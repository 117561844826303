<template>

<app-page theme="purple" :centralise="true">

	<com-filter :data="filter" v-if="step === 1" v-on:answered="onFilterAnswered" v-on:back="onLeave" />
	
	<com-intro :data="intro" v-if="step === 2 && filterAnswer" v-on:answered="stepNext" v-on:back="step -= 1" />

	<com-info :data="info" v-if="step === 2 && !filterAnswer" v-on:answered="onInfoAnswered" v-on:back="step -= 1" />

	<template v-for="(question, index) in questions">

		<com-question :key="question.name" :data="question" v-if="step === index + 3" v-on:answered="onQuestionAnswered" v-on:back="step -= 1" />

	</template>

</app-page>

</template>

<script>

export default {

	components: {
		'com-filter': () => import('./questions/Filter'),
		'com-intro': () => import('./questions/Intro'),
		'com-info': () => import('./questions/Info'),
		'com-question': () => import('./questions/Question')
	},

	data: function() {
	
		return {
			step: 1
		}

	},

	created: function() {

		this.$store.commit('recommendation', false)
		this.$store.commit('selection', false)
		this.$store.commit('completed', {})
		this.$store.commit('answers', {})

		if (this.$route.params.skipStep) {
			
			this.$store.commit('answer', {
				name: 'filter',
				value: 1
			})
			
			this.step = 2

		}

	},

	computed: {

		filterAnswer: function() {

			return this.$store.getters['answers'].filter

		},

		info: function() {

			return this.$store.getters['data'].questions.info

		},

		alert: function() {

			return this.$store.getters['data'].questions.alert

		},

		filter: function() {

			return this.$store.getters['data'].questions.filter

		},

		intro: function() {

			return this.$store.getters['data'].questions.intro

		},

		questions: function() {

			return this.$store.getters['data'].questions.questions

		}

	},

	methods: {

		stepNext: function() {

			this.step++

		},

		onLeave: function() {

			this.$router.push({
				name: 'Home'
			})

		},

		stepRecommendation: function() {

			this.$router.push({
				name: 'Recommendation'
			})

		},

		onInfoAnswered: function() {

			this.stepRecommendation()

		},

		onFilterAnswered: function() {

			this.stepNext()

		},

		onQuestionAnswered: function() {

			if (this.step == this.questions.length + 2) {

				this.stepRecommendation()

			} else {

				this.stepNext()

			}

		}

	}

}

</script>

<style scoped>

</style>
